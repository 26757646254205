import renzo from '../assets/renzo.png'
import gabby from '../assets/gabby.png'
import serge from '../assets/serge.png'

function Coaches(props) {
    return(
<div className="coach">
<div className="coachimg">
<img src={gabby} className='avatar-image' alt='gabby' />
<h1>
    Coach Gabrielle Larry
</h1>
<p>
University of Wisconsin All-American
<br/>
University of South Florida All-American
<br/>
 Passionate about  athletic excellence and personal growth
</p>
</div>

<div className="coachimg">
<img src={renzo} className='avatar-image' alt='renzo' />
<h1>
    Coach Lorenzo Larry
</h1>
<p>
 4x NJCAA All-American
<br/>
 4x NCAA Qualifier for University of Wisconsin 
<br/>
 4+ years of experience developing athletes
</p>
</div>

<div className="coachimg">
<img src={serge} className='avatar-image' alt='serge' />
<h1>
    Coach Serge Trezy
</h1>
<p>
 University of Wisconsin Track & Field
<br/>
 Dedicated to shaping young minds 
<br/>
 4 years of experience developing athletes
</p>
</div>

</div>
    ) 
  }
  
  export default Coaches;
  