
function Footer(props) {
    
    return(
<div className="footer">

<hr/>
<p>
    Copyright &copy; All Rights Reserved Golden Spikes Athletics 2024 
<br/>
    This Site Was Made By Digital Investors Hub
<br/>
</p>

</div>
    )
};

export default Footer;
        