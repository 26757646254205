import { Link } from "react-router-dom";


function Header(props) {
  return(
    <nav className="nav">
         <Link to="/">
         <div>Golden Spikes Athletics</div>
         </Link>
         <Link to="/register">
         <div className="head">Register</div>
         </Link>
         <Link to="/schedule">
         <div className="head">Schedule</div>
         </Link>
         <Link to="/roster">
         <div className="head">Roster</div>
         </Link>
    </nav>
  )
};

export default Header;
