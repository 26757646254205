import About from '../components/About';
import Dash from '../components/Dash';
import Bar from '../components/Bar';
import Coaches from '../components/Coaches';

function Home() {
  return (
    <div className="App">
      <Dash/>
      <Bar/>
      <About/>
      <Coaches/>
      <Bar/>
    </div>
  );
}

export default Home;
