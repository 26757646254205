import spike from '../assets/spike.png'
import uniforms from '../assets/uniforms.png'

function About(props) {
    return(
<div className="about">
<h1>
    AAU Track & Field
</h1>
<div className="aboutimg">
<img src={spike} className='abimg' alt='spike' />
<p>
    Within the realm of AAU track and field, we see boundless 
    opportunities to showcase our athletes' talents to colleges 
    and coaches, opening doors to scholarships that can shape their 
    academic journey alongside their athletic pursuits. Through strategic 
    partnerships and targeted exposure, we aim to connect our athletes with 
    opportunities that will not only advance their athletic careers but also 
    provide the foundation for lifelong success.
</p>
</div>

<h1>
    Golden Spikes Athletics
</h1>
<div className="aboutimg">
<img src={uniforms} className='abimg' alt='uniforms' />
<p>
At Golden Spikes Athletics, our journey began in January of 2024 with 
a clear purpose: to propel athletes towards their peak athletic potential, 
with an eye on collegiate and professional careers. With a dedicated and 
experienced staff, our mission extends beyond the track; we aim to not only 
cultivate exceptional athletes but also to serve as role models, instilling 
values that our athletes can carry with them into the wider world.
</p>
</div>

</div>
    ) 
  }
  
  export default About;
  