import { FiPhoneCall, FiInstagram } from "react-icons/fi";
import { FaFacebookMessenger } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";

function Headerr(props) {
  return(
    <nav className="nav">
                    <a href='https://www.instagram.com/goldenspikesathletics?igsh=MnlhaDRsODRzeXU='
                        className='Navs'>
                        <FiInstagram/> 
                    </a> 
    <a href='tel:+14074763793' className='Navs'>
             <FiPhoneCall />
    </a>
    <a href='https://www.facebook.com/share/jLYKbWKXQPGXy5Fs/?mibextid=LQQJ4d' className='Navs'>
             <FaFacebookMessenger />
    </a>
    <a href='mailto:goldenspikes24@gmail.com' className='Navs'>
    <HiOutlineMail />
    </a>
<br/>
         <p>
        Winter Garden, Florida
        </p>
    </nav>
  )
}

export default Headerr;
