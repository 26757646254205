import dm from '../assets/dm.png'
import jh from '../assets/jh.png'
import jf from '../assets/jf.png'
import nutral from '../assets/nutral.png'
import tm from '../assets/tm.png'
import dt from '../assets/dt.png'
import jd from '../assets/jd.png'
import gd from '../assets/gd.png'
import dc from '../assets/dc.png'
import ab from '../assets/ab.png'
import ja from '../assets/ja.png'

function Roster(props) {
    return(
<div className="roster">
<div className="rosterr">
<h1>
    Golden Spikes Athletics 
</h1>
<h2>
    Boys Roster
</h2>
<br/>
<div className="rost">
<img src={ja} className='avatar-image' alt='ja' />
    <h3>
    Joseph Auguste
    </h3>
<p>
Sprints and Mid-distance 
</p>
<p>
Senior / Orlando, FL
</p>
    </div>
<div className="rost">
<img src={ab} className='avatar-image' alt='ab' />
    <h3>
Adam Bissessar
    </h3>
    <p>
    Sprints and Hurdles 
    </p>
    <p>
    Junior/ Orlando, FL
    </p>
    </div>
<div className="rost">
<img src={dc} className='avatar-image' alt='dc' />
    <h3>
Dreyton Carson 
    </h3>
<p>
    Sprints and Jumps 
</p>
<p>
Sophomore/ Orlando, FL
</p>
    </div>
<div className="rost">
<img src={nutral} className='avatar-image' alt='nutral' />
    <h3>
Abberd Chery
    </h3>
<p>
    Sprints and Jumps 
</p>
<p>
Sophomore / Orlando, FL
</p>
    </div>
<div className="rost">
<img src={gd} className='avatar-image' alt='gd' />
    <h3>
Gary Davis
    </h3>
<p>
    Sprints and Hurdles  
</p>
<p>
Junior / Orlando, FL
</p>
    </div>
<div className="rost">
<img src={jd} className='avatar-image' alt='jd' />
    <h3>
Jorey Divra
    </h3>
<p>
    Mid-Distance 
</p>
<p>
Senior/ Orlando FL
</p>
    </div>
<div className="rost">
<img src={nutral} className='avatar-image' alt='nutral' />
    <h3>
Gavin Dunn
    </h3>
<p>
    Sprints and Hurdles  
</p>
<p>
Senior / Orlando, FL
</p>
    </div>
<div className="rost">
<img src={jf} className='avatar-image' alt='jf' />
    <h3>
Julian Farrell

    </h3>
<p>
    Sprints and Jumps
</p>
<p>
Sophomore / Orlando, FL
</p>
    </div>
<div className="rost">
<img src={jh} className='avatar-image' alt='jh' />
    <h3>
Jacob Hodge 
    </h3>
<p>
    Sprints 
</p>
<p>
7th Grade/ Orlando, FL
</p>
    </div>
<div className="rost">
<img src={dm} className='avatar-image' alt='dm' />
    <h3>
Dylan Moreno
    </h3>
<p>
    Sprints 
</p>
<p>
Freshman / Orlando, FL
</p>
    </div>

<div className="rost">
<img src={nutral} className='avatar-image' alt='nutral' />
    <h3>
Dayveon Nichols

    </h3>
<p>
    Sprints and Jumps
</p>
<p>
Senior  / Orlando, FL
</p>
    </div>
<div className="rost">
<img src={dt} className='avatar-image' alt='nutral' />
    <h3>
Daven Tolbert 
    </h3>
<p>
    Multi Events
</p>
<p>
Senior/ Orlando, FL
</p>
    </div>
<br/>
<br/>
<h2>
    Girls Roster
</h2>

<div className="rost">
<img src={nutral} className='avatar-image' alt='nutral' />
    <h3>
Regina Glenn 
    </h3>
<p>
    Sprints and Jumps 
</p>
<p>
3rd Grade / Orlando, FL
</p>
    </div>
<div className="rost">
<img src={nutral} className='avatar-image' alt='nutral' />
    <h3>
Jamese Heard
    </h3>
<p>
Sprints 
</p>
<p>
Senior/ Orlando, FL
</p>
    </div>
<div className="rost">
<img src={tm} className='avatar-image' alt='tm' />
    <h3>
Tabrea Miller 
  </h3>
<p>
    Sprints  
</p>
<p>
Senior/ Orlando, FL
</p>
    </div>
<div className="rost">
<img src={nutral} className='avatar-image' alt='nutral' />
    <h3>
Limyanna Yarn
    </h3>
<p>
    Sprints 
</p>
<p>
Junior/ Orlando, FL
</p>
    </div>
</div>

</div>
    ) 
  }
  
  export default Roster;
  