import { Routes, Route } from 'react-router-dom';

import Home from '../pages/Home';
import Show from '../pages/Show';
import Register from '../pages/Register';
import Roster from '../pages/Roster';
import Schedule from '../pages/Schedule';

function Main(props) {
    return(
        <main>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/people/:id' element={<Show />} />
                <Route path='/schedule' element={<Schedule />} />
                <Route path='/register' element={<Register />} />
                <Route path='/Roster' element={<Roster />} />
            </Routes>
        </main>
    )
  }
  
  export default Main;
  