
function Dash(props) {
    return(
        <div className="homepage">
<div className="home">
        
    <h1> 
    Golden Spikes Athletics
        <h2 className="dashp">
            USA/AAU Track & Field
        </h2>
    </h1>
    
</div>
</div>

    ) 
  }
  
  export default Dash;
  