import cash from '../assets/cash.png'

function Register(props) {
    return(
<div className="register">
<h1>
    Register For Summer
</h1>
<p>
Each athlete must pay a deposit
fee of $200.
<br/>
 The $200 fee will cover June 1- August 10, 2024 and cover 
the official summer 2024 competition uniform
</p>
<img src={cash} className='rimg' alt='cash' />
</div>
    ) 
  }
  
  export default Register;
  