import { Link } from "react-router-dom";

function Bar(props) {
    return(
<div className="bar">
<Link to="/register">
<button className="bttn">
     Register Now
</button>
</Link>

<Link to="/schedule">
<button className="bttn">
    View Schedule
</button>
</Link>
</div>
    ) 
  };
  
  export default Bar;
  