function Schedule(props) {
    return(
<div className="schedule">

<h1>
    Golden Spikes Athletics 
</h1>
<h2>
    AAU Summer Schedule
</h2>
<div className="sched">
    <h3>
    District Qualifier Shake Out
    </h3>
<p>
   May 18, 2024
</p>
<p>
   Plant City High School
</p>
    </div>
<div className="sched">
    <h3>
    AAU Florida Districts
    </h3>
    <p>
    06/01/2024
    </p>
<p>
Showalter Field, Winter Park FL
</p>
    </div>
<div className="sched">
    <h3>
    Speed Capital Florida Series
    </h3>
<p>
06/08/2024
</p>
<p>
Olympia High School, Orlando FL

</p>
    </div>
<div className="sched">
    <h3>
    Region Qualifiers
    </h3>
<p>
06/20/2024 - 06/23/2024
</p>
<p>
Showalter Field, Winter Park FL
</p>
    </div>
<div className="sched">
    <h3>
    Charger Elite 1st Annual Pre-National Invitatation
    </h3>
<p>
   June 29, 2024
</p>
<p>
   Osceola High School
</p>
    </div>
<div className="sched">
    <h3>
    AAU Primary Nationals Championships

    </h3>
<p>
July 7 - 8, 2024
</p>
<p>
University of North Florida - Hodges Stadium

</p>
    </div>
<div className="sched">
    <h3>
    Meet on the Hill 
    </h3>
<p>
7/13/2024 
</p>
<p>
Lake Minneola High School
</p>
    </div>
<div className="sched">
    <h3>
    AAU Junior Olympic Games 

    </h3>
<p>
July 27- August 3 
</p>
<p>
Greensboro, North Carolina
</p>
    </div>

</div>
    ) 
  }
  
  export default Schedule;
  